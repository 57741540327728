@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
/* src/toastStyles.css */
.custom-toast {
    min-height: 15px !important;  /* You can change this to any height */
    padding: 0px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  
/* custom-tree.css */
.node__root > circle {
  fill: rgb(25, 15, 230);
}

.node__branch > circle {
  fill: yellow;
}

.node__leaf > circle {
  fill: green;
  /* Let's also make the radius of leaf nodes larger */
  r: 20;
}
:root{
  --primary-color: #26c6da;
  --primary-color-hover: rgba(13,163,182,0.08);
  --primary-color-active: rgba(13,163,182,0.16);
  --secondary-color: #ffda68;
  --secondary-color-hover: #ffe082;
  --secondary-color-active: #ffecb3;
  --warning-color: #f44336;
  --warning-color-hover: #ffcdd2;
  --warning-color-active: #ef9a9a;
  --drop-shadow: 0 1px 6px rgba(0,0,0,0.1),0 4px 24px rgba(0,0,0,0.06);
  --lift-shadow: 0 1px 4px rgba(0,0,0,0.1),0 4px 12px rgba(0,0,0,0.06);
  --color-text: 1, 41, 46;
  --color-background: 255,255,255;
  --color-dark-background: 25,33,36;
  --grey-1: rgb(120, 144, 156);
  --color-input-border: 228,232,235;
  --container-width: 60rem;
  --rounded-radius: var(--spacing-unit-xmicro);
  --site-margin: var(--spacing-unit);
  --spacing-unit-xxmicro: 3px;
  --spacing-unit-xmicro: 0.25rem;
  --spacing-unit-micro: 0.3535534rem;
  --spacing-unit-tiny: 0.5000000266rem;
  --spacing-unit-small: 0.7071068376rem;
  --spacing-unit: 1.0000001064rem;
  --spacing-unit-large: 1.4142137505rem;
  --spacing-unit-xlarge: 2.0000003193rem;
  --spacing-unit-xxlarge: 2.8284276515rem;
  --spacing-unit-xxxlarge: 4.0000008514rem;
  --spacing-unit-xxxxlarge: 5.6568556041rem;
  --font-weight-regular: 400;
  --font-weight-text: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --home-hero-font-size-a: 3rem;
  --home-hero-font-size-b: 2.7rem;
  --home-hero-font-size-c: 1.5rem;
  --home-hero-font-size-d: 1.5rem;
}
@media screen and (min-width: 20rem){
  :root{
    --home-hero-font-size-a: 3.6931719654rem;
    --home-hero-font-size-b: 3.395216879rem;
    --home-hero-font-size-c: 2rem;
    --home-hero-font-size-d: 1.5rem;
  }
  .u-margin-bottom-xxlarge {
    margin-bottom: calc(var(--spacing-unit-xxxxlarge) * 1.25);
  }
  .u-margin-top-xxlarge {
    margin-top: calc(var(--spacing-unit-xxxxlarge) * 1.25);
  }
}
@media screen and (min-width: 60rem){
  :root {
      --home-hero-font-size-b: 4.55209399rem;
      --home-hero-font-size-a: 5.0377201155rem;
      --home-hero-font-size-c: 3rem;
      --home-hero-font-size-d: 2rem;
  }
  .u-margin-bottom-xxlarge {
    margin-bottom: var(--spacing-unit-xxxxlarge);
  }
  .u-margin-top-xxlarge {
    margin-top: var(--spacing-unit-xxxxlarge);
  }
  .form-input {
    font-size: 1.21875rem;
  }
  .primaryButton{
    width: 10rem;
  }
}

@media screen and (max-width: 60rem) and (min-width: 20rem){
  .form-input {
      font-size: calc(1.1375rem + 0.08125*(100vw - 20rem)/40);
  }
}


body {
  margin: 0;
  font-family: 'IBM Plex Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern" 1,"liga" 1,"calt" 1;
    font-variation-settings: "wdth" 95;
    line-height: 1.55;
    -webkit-tap-highlight-color: transparent;
    background-color: rgba(var(--color-background),1);
    color: rgba(var(--color-text),1);
}

.flex{
  display: flex;
}

.flex-row{
  flex-direction: row;
}

.flex-column{
  flex-direction: column;
}

.m-auto{
  margin: 0 auto;
}

.o-wrapper-l {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--site-margin);
  padding-right: var(--site-margin);
  max-width: calc(var(--container-width) + var(--site-margin) * 2);
}

.hero{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.welcomeText{
  font-size: var(--home-hero-font-size-a);
  letter-spacing: -0.0075em;
  line-height: 1.1;
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-unit-tiny);
}

.descriptionText{
  font-size: var(--home-hero-font-size-c);
  line-height: 1.35;
  font-weight: var(--font-weight-text);
  letter-spacing: -0.0075em;
  margin-bottom: var(--spacing-unit-large);
}

.actionText{
  font-size: var(--home-hero-font-size-d);
  line-height: 1.35;
  font-weight: var(--font-weight-regular);
  letter-spacing: -0.0075em;
  margin-bottom: var(--spacing-unit);

}

.username{
  color: var(--primary-color);
  cursor: pointer;
  position: relative;
}

.username.copied::before{
  width: -webkit-max-content;
  width: max-content;
  content: 'Copied to clipboard';
  background: rgba(var(--color-dark-background),1);
  box-shadow: var(--lift-shadow);
  padding: var(--spacing-unit-xmicro);
  border-radius: var(--spacing-unit-micro);
  font-size: 0.8rem;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.username.copied::after{
  content: '';
  background: rgba(var(--color-dark-background),1);
  padding: var(--spacing-unit-xmicro);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transform: rotate(45deg);
}

.callBox, .disclaimer{
  margin-bottom: var(--spacing-unit);
}

.form-input{
  padding: var(--spacing-unit) var(--spacing-unit-large);
  width: 100%;
  border: 3px solid rgba(var(--color-input-border),1);
  border-radius: calc(var(--rounded-radius)*2);
  font-weight: var(--font-weight-text);
  line-height: 1.2;
  background-color: rgba(var(--color-background),1);
  color: rgba(var(--color-text),1);
  transition: box-shadow .2s cubic-bezier(.4,0,.2,1);
  outline: none;
  margin-right: var(--spacing-unit-large);
}

.form-input::placeholder, .form-input:-moz-placeholder{
  font-weight: var(--font-weight-text);
  line-height: 1.2;
  color: rgba(var(--color-text),1);
}

.form-input:hover {
  box-shadow: 0 1px 4px #0000001a, 0 4px 12px rgba(0,0,0,.06);
  box-shadow: var(--lift-shadow);
}

.form-input:focus{
  border: 3px solid var(--primary-color);
  box-shadow: 0 1px 4px #0000001a, 0 4px 12px rgba(0,0,0,.06);
  box-shadow: var(--lift-shadow);
}

.highlight {
  background: linear-gradient(180deg,rgba(255,255,255,0) 60%,var(--secondary-color-active) 40%);
}

.primaryButton{
  border: none;
  outline: none;
  cursor: pointer;
  font-size: var(--home-hero-font-size-d);
  font-weight: var(--font-weight-semibold);
  line-height: 1.25;
  padding: var(--spacing-unit-small);
  background-color: var(--secondary-color);
  color: #1f292e;
  border-radius: .25rem;
  border-radius: var(--rounded-radius);
  transition: background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1),box-shadow .2s cubic-bezier(.4,0,.2,1);
}

.primaryButton:hover, .primaryButton:active{
  box-shadow: var(--lift-shadow);
}

.primaryButton:hover{
  background-color: var(--secondary-color-hover);
}

.primaryButton:active{
  background-color: var(--secondary-color-active);
}

.alertButtonPrimary,.alertButtonSecondary{
  border: none;
  outline: none;
  width: 4rem;
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
  line-height: 1.25;
  padding: var(--spacing-unit-xmicro);
  color: #1f292e;
  border-radius: .25rem;
  border-radius: var(--rounded-radius);
  transition: background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1),box-shadow .2s cubic-bezier(.4,0,.2,1);
}

.alertButtonPrimary{
  background-color: var(--primary-color);
}

.alertButtonSecondary{
  background-color: var(--warning-color);
}

.alertButtonPrimary:hover, .alertButtonPrimary:active, .alertButtonSecondary:hover, .alertButtonSecondary:active{
  box-shadow: var(--lift-shadow);
}

.alertButtonPrimary:hover{
  background-color: var(--primary-color-hover);
}

.alertButtonSecondary:hover{
  background-color: var(--warning-color-hover);
}

.alertButtonPrimary:active{
  background-color: var(--primary-color-active);
}

.alertButtonSecondary:active{
  background-color: var(--warning-color-active);
}


.incomingCallContainer{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: display .2s cubic-bezier(.4,0,.2,1);
}

.incomingCall{
  height: auto;
  width: -webkit-max-content;
  width: max-content;
  background-color: #fff;
  border-radius: calc(var(--rounded-radius)*2);
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: var(--lift-shadow);
  padding: var(--site-margin);
  justify-content: space-between;
  animation: callAnimation 2s infinite ease-in-out;
}

@keyframes callAnimation {
  0% {
    transform: translate(-50%,-50%) scale(1) ;
  }
  50% {
    transform: translate(-50%,-50%) scale(1.1);
  }
  100% {
    transform: translate(-50%,-50%) scale(1);
  }
}

.callerID{
  line-height: 1.35;
  font-weight: var(--font-weight-semibold);
  letter-spacing: -0.0075em;
}

.incomingCallButtons{
  justify-content: space-between;
  margin-top: var(--spacing-unit);
}

.callContainer{
  width: 100vw;
  height: 100vh;
}

.userVideoContainer{
  max-width: 9rem;
  max-height: 9rem;
  height: auto;
  width: auto;
  position: absolute;
  top: var(--spacing-unit-micro);
  left: var(--spacing-unit-micro);
  z-index: 99;
}

.userVideo{
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: var(--lift-shadow);
}

.partnerVideoContainer{
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0,0,0,1);
}

.partnerVideo{
  width: 100%;
  height: 100%;
}

.partnerVideo.cover{
  object-fit: cover;
}

.controlsContainer{
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: space-around;
  height: 4rem;
  z-index: 999;
  width: 100vw;
  background-color: rgba(0,0,0,0.1);
}

.iconContainer{
  padding: 10px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  width: 3.25rem;
  height: 3.25rem;
  cursor: pointer;
  background-color: var(--primary-color-hover);
  box-shadow: var(--lift-shadow);
  transition: background-color .2s cubic-bezier(.4,0,.2,1);
}

.iconContainer:hover{
  background-color: rgba(var(--color-background),0.2);
}

.iconContainer img{
  width: 100%;
  height: auto;
}

.headerWrapper{
  max-width: calc(var(--container-width) + var(--spacing-unit)*2);
  margin: 0 auto;
  padding: 0 var(--spacing-unit);
}

.headerContainer{
  justify-content: space-between;
  align-items: center;
}

.githubStar{
  margin-top: var(--spacing-unit-micro);
}

.headerLogoLinkWrapper{
  margin: var(--spacing-unit-xxmicro) 0;
}

.headerLogoLink{
  background-color: #fff;
  padding: var(--spacing-unit-micro) var(--spacing-unit-tiny);
  transition: background-color .2s cubic-bezier(.4,0,.2,1);
  border-radius: var(--spacing-unit-xmicro);
}

.headerLogoLink a{
  text-decoration: none;
  color: var(--color-text);
  text-align: center;
  white-space: nowrap;
}

.headerLogoLink:hover{
  background-color: var(--primary-color-hover);
}

.headerLogoLink:active{
  background-color: var(--primary-color-active);
}

.headerLogo{
  line-height: 1.2;
  align-items: center;
  font-size: 1.6518974128rem;
  padding: var(--spacing-unit-xmicro) 0;
}

.logoImg{
  flex-shrink: 0;
  height: 1.5em;
}

.logoImg img{
  height: inherit;
  width: auto;
}

.logoText{
  margin-left: .2em;
  display: flow-root;
  font-weight: 700;
  font-variation-settings: "wdth" 85;
}

.logoText:before{
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: -.2075em;
}

.logoText:after{
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-bottom: -.2075em;
}

.dropShadow{
  box-shadow: var(--drop-shadow);
}

.footerWrapper{
    width: 100%;
    background-color: rgba(var(--color-dark-background),1);
    text-align: center;
    color: var(--grey-1);
    padding: var(--spacing-unit-xxlarge) 0;
    position: fixed;
    bottom: 0;
  }
  
  .footerContainer{
    max-width: var(--container-width);
    margin: 0 auto;
  }
  
  .self a{
    text-decoration: none;
    font-weight: var(--font-weight-text);
    transition: color .2s cubic-bezier(.4,0,.2,1), -webkit-text-decoration .2s cubic-bezier(.4,0,.2,1);
    transition: text-decoration .2s cubic-bezier(.4,0,.2,1), color .2s cubic-bezier(.4,0,.2,1);
    transition: text-decoration .2s cubic-bezier(.4,0,.2,1), color .2s cubic-bezier(.4,0,.2,1), -webkit-text-decoration .2s cubic-bezier(.4,0,.2,1);
    color: var(--grey-1);
  }
  
  .self a:hover, .self a:focus{
    text-decoration: underline;
    color: #fff;
  }
.PdfViewer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.PdfViewer .header {
  width: 100%;
  height: 60px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #00a5e4;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}

.PdfViewer .webviewer{
  height:100%;
}
